/* Copyright © TheSchoolProject - All Rights Reserved */

import 'polyfills'

import * as Sentry from '@sentry/browser'

if (window.settings.sentryDSN.length > 0) {
  Sentry.init({
    dsn: window.settings.sentryDSN,
    // ignoreErrors: [],
    blacklistUrls: [/scr\.kaspersky-labs\.com/i],
  })
}

window.addEventListener(
  'load',
  () => {
    // table heads are fixed on scroll
    window.$('.table-head-fixed table').stickyTableHeaders()

    window
      .$('.table-head-fixed')
      .scroll(() => window.$(window).trigger('resize.stickyTableHeaders'))
  },
  false,
)

function setInvoiceLineAmounts () {
  const selects = document.querySelectorAll('select[data-item-select]')
  for (const select of selects) {
    select.addEventListener('change', event => {
      const formPrefix = event.target.dataset.formPrefix
      const amountInput = document.getElementById(`id_${formPrefix}-amount`)
      amountInput.value = window.INVOICE_ITEMS_AMOUNTS[event.target.value]
    })
  }
}

window.setInvoiceLineAmounts = setInvoiceLineAmounts

function addInvoiceLine () {
  const addRowButton = document.getElementById('add-invoice-row')
  addRowButton.addEventListener('click', event => {
    const rowCount = document.getElementsByClassName('row-block').length
    var clone = document.getElementById('row-block-' + rowCount).cloneNode(true)
    clone.id = 'row-block-' + (rowCount + 1)
    clone.innerHTML = clone.innerHTML
      .split('form-' + (rowCount - 1))
      .join('form-' + rowCount)
    clone.innerHTML = clone.innerHTML.replace(
      'Ligne ' + rowCount,
      'Ligne ' + (rowCount + 1),
    )
    document.getElementById('invoices-rows').append(clone)
    document.getElementById('id_form-TOTAL_FORMS').value = rowCount + 1
    setInvoiceLineAmounts()
  })
}

window.addInvoiceLine = addInvoiceLine
